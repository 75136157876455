// dropdowns will be displayed when there is no javascript

.dropdown-menu {
	position: static;

	&.datepicker-dropdown {
		position: absolute;
	}

	.js & {
		display: none;

		> li > a {
			white-space: nowrap;
		}
	}
	&, .open & {
		display: block;
	}

	.open & {
		position: absolute;
	}

	> li > a {
		white-space: normal;
	}
}