[class^="icon-"]:not(.fa), [class*=" icon-"]:not(.fa) {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon';
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-facebook:before {
    content: "\ea90";
  }
  .icon-instagram:before {
    content: "\ea92";
  }
  .icon-twitter:before {
    content: "\ea96";
  }
  .icon-youtube:before {
    content: "\ea9d";
  }
  .icon-pinterest:before {
    content: "\ead1";
  }
  .icon-twitter-x:before {
    content: "\e900";
  }