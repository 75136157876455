// specific styles for Style Guide
body.admin-styleguide {
	background: @generic-bg;
}
.sg-box {
	margin-top: 60px;
}

.sg-navbar-container {
	height: @navbar-height;
	position: relative;
}

@media @max-xs {
	.sg-secondary-title {
		margin-top: 28px;
	}
}
@media @min-sm {
	.sg-secondary-title {
		+ .col-md-9 {
			.row {
				margin-bottom: 10px;
			}
		}
	}
}