body.modal-open {
  @media @max-md {
    position: fixed;
  }
}
.modal {
	&-content {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
	&-header {
		background: @gray-darker;
		border-bottom: 3px solid @swatch-13;
		padding: 14px 15px 9px;
	}

	&-title {
		color: @swatch-16;
		font-family: @main-font-regular-medium;
		font-size: 17px;
		text-align: center;
		padding-left: 30px;
		padding-right: 30px;
		word-break: break-word;
	}
	.close {
		color: @swatch-14;
		font-size: 28px;
		margin-top: -2px;
		opacity: 1;

		&:hover {
			opacity: 0.7;
		}
	}

	.image-form-container {
		.btn-minimal {
			display: none;
		}
	}
}

@media only print {
	.modal-open {
		#main, .modal-header {
			display: none;
		}
		.modal-dialog {
			margin: 0 !important;
			max-width: 900px;
			width: 100%;
		}
		.modal-content {
			border: none;
		}
	}
	.item {
		&-image-actions, &-image-caption {
			display: none !important;
		}
		&-info-keywords a:after {
			display: none;
		}
	}
}
