.buy-prints-checkout {
	h1.bold {
		font-size: 30px;
		margin-top: 55px;
	}
}
.checkout-heading {
	.clearfix;

	h1.bold {
		@media @min-sm {
			float: left;
		}
	}
	a {
		color: @brand-primary;
    	display: block;
		font-weight: bold;

		@media @min-sm {
			float: right;
    		margin-top: 68px;
		}

		&:before {
			.ico-font();
			content: @fa-var-angle-double-left;
			margin-right: 5px;
		}
	}
}