.testimonial-row {
  justify-content: space-between;
  display: flex;
  margin-top: 30px;

  &.is-reverse {
    flex-flow: row-reverse;
  }
}

.testimonial-image {
  margin-right: 20px;
  margin-top: 16px;
  max-width: 70px;

  @media (min-width: @screen-sm-min) {
    max-width: 180px;
  }

  .is-reverse & {
    margin-right: 0;
    margin-left: 20px;
  }

  img {
    max-width: 100%;
  }
}

.testimonial-content {
  flex: 1;

  p {
    color: @swatch-2;
    line-height: 1.3;
    text-style: italic;
  }

  footer {
    color: @swatch-9;

    &:before, &:after {
      display: none;
    }
  }
}
