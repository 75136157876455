.navbar {
    min-height: 50px;
    border: none;
    border-radius: 0;
    margin-bottom: 0;
    text-transform: uppercase;

    .navbar-holder {
        min-height: 50px;
    }

    .collapse-content {
        height: 100%;
        display: flex;
    }

    .search-box.navbar-collapse {
        padding-right: 0;
        width: auto;

        &.collapsing,
        &.in {
            @media @max-md {
                position: absolute;
                z-index: 10;
                width: 100%;
            }
        }
    }

    .navbar-collapse.no-line {
        padding-right: 0;
        &::after {
            display: none;
        }
    }

    .navbar-toggle {
        background: transparent !important;
        border: none;
        border-radius: 0;
        height: 51px;
        margin: 0;
        padding: 9px 13px 9px 14px;
        text-align: center;
        width: 50px;

        &:hover {
            .icon-bar {
                background-color: @brand-primary;
            }
            .icon-search {
                color: @brand-primary;
            }
        }
        &.collapsed {
            .icon-bar {
                background-color: @swatch-2;
            }
            .icon-search {
                color: @swatch-2;
            }
        }
        .icon-bar {
            background-color: @brand-primary;
            height: 4px;
        }
        .icon-search {
            color: @brand-primary;
            font-size: 23px;
        }
    }
    .navbar-nav-toggle {
        border-left: 1px solid @mobile-nav-link-border;
    }
    .navbar-search-toggle {
        border-right: 1px solid @mobile-nav-link-border;
    }
    .navbar-header {
        text-align: center;
    }
	.navbar-nav {
        display: flex;
	    > li {
			&.active {
				a {
                    &, &:hover {
					   color: @swatch-2;
                    }
				}
			}
	    	> a {
                color: @swatch-2;
                font-family: @main-font-regular-medium;
                // text-transform: uppercase;
                display: flex;
                align-items: center;
                letter-spacing: 1.12px;
                padding-left: 11px;
                padding-right: 11px;

				&:active, &:focus, &:hover {
					color: @swatch-2;
				}
	    	}
	    	&.login-link {
	    		> a {
	    			color: @swatch-2;

                    &:hover {
                        color: @swatch-2;
                    }
	    		}
	    	}
	    }
	}
    .logo {
        margin: 7px 0 0;

        img {
            height: 36px;
            width: 140px;
        }
    }
}

.navbar-default {
    @media @min-lg {
        padding-inline: ~"max(
            (100% - 1705px) / 2,
            17px
        ) !important";
    }

    @media @min-md {
        display: grid;
        grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
        padding-inline: 17px;

        &::after,
        &::before {
            content: none;
        }
    }

    .navbar-header {
        @media @min-md {
            justify-self: start;
        }
    }

    .search-box {
        @media @min-md {
            justify-self: end;
        }
    }
}

.credits-info {
  color: @swatch-15;
  font-family: @main-font-regular-bold;
  height: @navbar-height;
  line-height: @navbar-height;
  display: flex;

  @media @min-md {
    float: left;
  }
}

.credits-info-a {
    color: @swatch-2;
    text-transform: uppercase;
    font-family: @main-font-regular-medium;
    display: flex;
    align-items: center;
    line-height: 20px;
    padding-left: 6px;

    &:hover {
        color: @swatch-2;
        text-decoration: none;
    }
}

.credits-info-number {
  background: @swatch-2;
  border-radius: (@credits-info-circle-size / 2);
  color: white;
  display: inline-block;
  height: @credits-info-circle-size;
  line-height: @credits-info-circle-size;
  min-width: @credits-info-circle-size;
  padding-left: 3px;
  padding-right: 4px;
  text-align: center;
  flex: none;
  margin-right: 2px;
}

.top-bar-section {
    background: @swatch-2;
    color: #e5e5e5;
    padding: 10px 0;
    min-height: 42px;

    .container-fluid {
        display: flex;
        justify-content: center;
        position: relative;
    }

    .close-thin {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }

    a {
        &:hover {
            color: @swatch-1;
        }
    }
}

.search-box .form-control {
    width: 100%;
    letter-spacing: .3px;
}

.search-box .search-box-wrapper .form-group {
    width: 100%;
}

.top-bar {
    font-size: 16px;
    padding: 0 40px;
    letter-spacing: .34px;
    display: flex;
    flex-direction: column;

    @media @max-xs {
        padding-left: 0;
    }

    span {
        display: none;

        &:first-child {
            display: block;
        }
    }
}

.close-thin {
    width: 30px;
    height: 30px;
    position: relative;
    cursor: pointer;

    &::after,
    &::before {
        content: "";
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 20px;
        bottom: 0;
        margin: auto;
        transform-origin: 50%;
        background: @white-color;
    }

    &::after {
        transform: rotate(-45deg);
    }

    &::before {
        transform: rotate(45deg);
    }
}

.navbar {
    > .container-fluid {
        &::before,
        &::after {
            display: none;
        }
    }
}

@media @max-sm {
    .navbar {
        .navbar-holder {
            float: none !important;
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            overflow: visible;
            z-index: 10;
            display: flex;
        }
        .navbar-collapse {
            background: @swatch-17;
            border-style: none;
            box-shadow: inset 0 3px 2px -1px rgba(0, 0, 0, 0.2), 0 2px 3px 0 rgba(0, 0, 0, 0.3);
            float: none !important;
            overflow: hidden;
            padding: 0;
            text-align: left;
            width: 100%;
        }
        .collapse-content {
            margin: 32px 8% 118px;
            flex-direction: column;
            height: 200px;
        }

        .navbar-nav {
            margin: 0;
            flex-direction: column;
		    > li {
				&.active {
					> a {
                        &, &:hover {
						  background: @brand-primary;
                        }
					}
				}
				> a {
                    border-bottom: 1px solid @mobile-nav-link-border;
					&:active, &:focus, &:hover {
						background: @brand-primary;
					}
				}
			}
        }
        .logo {
            margin-left: 12px;
        }
    }
}


@media @min-md {
    .navbar {
        > .container-fluid {
            position: relative;
            display: flex;
            justify-content: space-between;
            padding-left: 17px;
            padding-right: 17px;
            max-width: 1739px;

        }

        .search-box {
            padding-top: 8px;
            order: 3;
        }

        .navbar-holder {
            // left: 180px;
            // position: absolute;
            // right: 0;
            // top: 0;
            display: flex;
        }
        .navbar-collapse {
            // border-right: 1px solid @gray-dark;
            float: right;
            height: @navbar-height !important;
            padding-left: 8px;
            padding-right: 8px;
            display: flex;
            position: relative;

            &:last-child {
                &::after {
                    display: none;
                }
            }

            &::after {
                content: "";
                width: 1px;
                height: 11px;
                background: @swatch-4;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }

        .menu-collapse {
            float: left;
        }

        .navbar-nav {
            float: none;
            text-align: center;
            display: flex;
            > li {
                display: flex;

                &.active {
                    > a {
                        &, &:hover {
                            background: none;
                            color: @swatch-2;
                            &:before {
                                background: @brand-primary;
                            }
                        }
                    }
                }
                > a {
                    border: none;
                    // padding: 0 6px;
                    position: relative;

                    &:hover, &:active, &:focus {
                        background: none;
                        color: @swatch-2;
                        &:before {
                            background: @brand-primary;
                        }
                    }

                    &:before {
                        content: " ";
                        display: block;
                        height: 5px;
                        left: 1px;
                        position: absolute;
                        right: 0px;
                        top: 0;
                    }
                }
            }
        }
    }
    .search-box .search-box-wrapper {
        display: flex;
        width: 210px;
    }
}
@media @md-only {
    .navbar {
        .navbar-collapse {
            padding-left: 6px;
            padding-right: 6px;
        }
    }
}
@media @max-md {
    .navbar {
        > .container-fluid {
            width: 100%;
        }
    }
}
