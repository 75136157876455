.no-results-chat-btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 75px;

  @media @min-md {
    max-width: 128px;
  }

  img {
    max-width: 100%;
  }
}
