// took idea from http://ryanfait.com/resources/footer-stick-to-bottom-of-page/

@media @min-md {
	html, body {
		height: 100%;
	}

	.wrapper {
		margin: 0 auto -@footer-height;
		min-height: 100%;
		// height: auto !important;
		// height: 100%;
	}

	.footer-wrapper {
		overflow: hidden;
	}
	.footer-wrapper, .push {
		min-height: @footer-height;
	}
}