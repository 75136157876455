.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	border: 2px solid @swatch-16;
	text-transform: uppercase;
	padding: .5em 1em;
	color: @swatch-2;
	background: transparent;
	font-family: @main-font-regular-bold;
	font-size: 14px;
	box-shadow: none;
	transition: background-color .3s;
	letter-spacing: 1.12px;

	&:hover, &:focus, &:active {
		background: @swatch-16;
		box-shadow: none;
		border-color: @swatch-16;
		color: @swatch-2;
	}

	.fa {
		color: inherit;
	}

	&-link {
		&:hover {
			text-decoration: none;
		}
	}

	// button types
	// &-default {
	// 	.button-shadow(@btn-default-shadow-color);

	// 	&:hover {
	// 		background-color: @btn-default-hover-bg;
	// 	}
	// 	&:active {
	// 		.button-active-shadow(@btn-default-shadow-color);
	// 	}
	// 	&.disabled {
	// 		.button-shadow(@btn-default-shadow-color);
	// 	}

	// 	.btn-group > & + .dropdown-toggle {
	// 		border-left: 1px solid @btn-default-shadow-color;
	// 	}

	// 	.fa {
	// 		color: @btn-default-shadow-color;			
	// 	}
	// }
	// &-info {
	// 	font-family: @main-font-regular;
	// 	font-size: 15px;
	// 	text-transform: none;
	// 	letter-spacing: .3px;
	// 	color: @swatch-7;
		
	// 	&:hover {
	// 		background: @swatch-16;
	// 		box-shadow: none;
	// 		border-color: @swatch-16;
	// 		color: currentColor;
	// 	}
	// 	&:active {
	// 		background: @swatch-16;
	// 		box-shadow: none;
	// 		border-color: @swatch-16;
	// 		color: currentColor;
	// 	}
	// 	&.disabled {
	// 		background: @swatch-16;
	// 		box-shadow: none;
	// 		border-color: @swatch-16;
	// 		color: currentColor;
	// 	}

	// 	.btn-group > & + .dropdown-toggle {
	// 		border-left: 1px solid @swatch-16;
	// 	}

	// 	.fa {
	// 		color: currentColor;
	// 	}
	// }
	// &-primary {
	// 	.button-shadow(@btn-primary-shadow-color);

	// 	&:hover {
	// 		.button-shadow(lighten(@btn-primary-shadow-color, 10%));
	// 		background-color: @btn-primary-hover-bg;
	// 	}
	// 	&:active {
	// 		.button-active-shadow(@btn-primary-shadow-color);
	// 	}
	// 	&.disabled {
	// 		.button-shadow(@btn-primary-shadow-color);
	// 	}

	// 	.btn-group > & + .dropdown-toggle {
	// 		border-left: 1px solid @btn-primary-shadow-color;
	// 	}

	// 	.fa {
	// 		color: @btn-primary-shadow-color;			
	// 	}
	// }
	// &-danger {
	// 	.button-shadow(@btn-danger-shadow-color);
		
	// 	&:hover {
	// 		.button-shadow(lighten(@btn-danger-shadow-color, 10%));
	// 		background-color: @btn-danger-hover-bg;
	// 	}
	// 	&:active {
	// 		.button-active-shadow(@btn-danger-shadow-color);
	// 	}
	// 	&.disabled {
	// 		.button-shadow(@btn-danger-shadow-color);
	// 	}

	// 	.btn-group > & + .dropdown-toggle {
	// 		border-left: 1px solid @btn-danger-shadow-color;
	// 	}

	// 	.fa {
	// 		color: @btn-danger-shadow-color;			
	// 	}
	// }

	// button sizes
	&-lg, &-group-lg > & {
		font-size: 16px;
		padding: 12px 30px;
	}
	&-sm, &-group-sm > & {
		font-size: 13px;
		padding: 8px 25px;
	}
	&-with-icon {
		padding-left: 18px;
		padding-right: 15px;
	}

	&-search {
  		line-height: 28px;
  		padding: 5px 8px 4px 9px;
		font-size: 28px;

		.fa {
			color: @white-color;
		}
	}
	&-control {
		border: 2px solid;
		border-radius: 50%;
		color: @btn-control-color;
		display: inline-block;
		font-size: 12px;
		height: 31px;
		line-height: 28px;
		.opacity(1);
		text-align: center;
		width: 31px;

		&:hover {
			.opacity(.65);
		}

		.fa-chevron-left {
			margin-right: 2px;
		}
		.fa-chevron-right {
			margin-left: 2px;
		}
	}
	&-tiny-icon {
		border: 2px solid;
		border-radius: 50%;
		display: inline-block;
		font-size: 10px;
		height: 28px;
		text-align: center;
		width: 28px;

		&:hover {
			color: @swatch-2;
		}
	}
	&-icon-up {
		color: @btn-control-color;
		padding-left: 1px;
		padding-top: 4px;
	}
	&-icon-remove {
		color: @white-color;
		padding-top: 4px;
	}
	&-minimal {
		.btn-outline;
		// background: none;
		// border: none;

		// &:hover, &:focus {
		// 	outline: none;
		// 	text-decoration: none;
		// }

		// &-danger {
		// 	color: @btn-danger-bg;
			
		// 	&:hover, &:focus {
		// 		color: darken(@btn-danger-bg, 15%);
		// 	}
		// }
		// &-info {
		// 	color: @btn-info-bg;

		// 	&:hover, &:focus {
		// 		color: darken(@btn-info-bg, 15%);
		// 	}
		// }
	}
	&-call-to-action {
		font-family: @main-font-regular-bold;
		text-transform: uppercase;

		.fa {
			color: @white-color;
			font-size: 36px;
			margin-right: 9px;
			text-shadow: 0 3px 0 @gray-light;
			vertical-align: middle;
		}
	}
	&-with-badge {
		padding: 9px 15px 6px;
		.badge {
			background: @brand-primary;
			color: @white-color;
			margin-left: 30px;
			top: 1px;
		}
	}
	&-outline {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		border: 2px solid @swatch-16;
		text-transform: uppercase;
		padding: .5em 1em;
		color: @swatch-2;
		background: transparent;
		font-family: @main-font-regular-bold;
		font-size: 14px;
		box-shadow: none;
		transition: background-color .3s;
		letter-spacing: 1.12px;

		&:hover, &:focus {
			background: @swatch-16;
			box-shadow: none;
			border-color: @swatch-16;
			color: @swatch-2;
		}

		.fa {
			color: inherit;
		}
	}
}

.btn-outline {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	border: 2px solid @swatch-16;
	text-transform: uppercase;
	padding: .5em 1em;
	color: @swatch-2;
	background: transparent;
	font-family: @main-font-regular-bold;
	font-size: 14px;
	box-shadow: none;
	transition: background-color .3s;
	letter-spacing: 1.12px;

	&:hover, &:focus, &:active {
		background: @swatch-16;
		box-shadow: none;
		border-color: @swatch-16;
		color: @swatch-2;
		text-decoration: none;
	}

	.fa {
		color: inherit;
	}

	.fa-arrow-circle-right {
		display: none;
	}
}

// .btn.btn-default[data-toggle="dropdown"] {
// 	font-size: 15px;
// 	border-radius: 4px 0 0 4px;
// 	border: 2px solid @swatch-7;
// 	display: inline-flex;
// 	align-items: center;
// 	justify-content: center;
// 	text-transform: uppercase;
// 	padding: 0 1.9em 0 .6em;
// 	color: @swatch-16;
// 	background: transparent;
// 	box-shadow: none;
// 	transition: background-color .3s;
// 	letter-spacing: .3px;
// 	min-height: 32px;
// 	text-transform: none;
// 	font-family: @main-font-regular;
// }

// .btn.btn-default.dropdown-toggle[data-toggle="dropdown"] {
// 	border-radius: 0 4px 4px 0;
// 	border-left: 0;
// 	padding: 8px 13px;
// }

// .dropdown-toggle[data-toggle="dropdown"] .caret {
// 	border-top: 6px dashed @swatch-16;
// 	border-right: 6px solid transparent;
// 	border-left: 6px solid transparent;
// }

// icon position, useful not only for btn, but for btn-minimal
.icon-to-right {
	margin-left: 4px;
}
.icon-to-left {
	margin-right: 4px;
}

// button groups
body .btn-group {
	display: inline-flex;
}

.btn-group {
	&.open {
		.btn.dropdown-toggle,
		.lightbox-item-actions-btn.dropdown-toggle {
			box-shadow: none;
			border-color: @swatch-16;
			background: @swatch-16;
		}
	}
	.btn {
		padding: 8px 13px 6px 17px;
		text-transform: none;
	}
	.dropdown-menu {
		> li > a {
			font-family: @main-font-regular-bold;
		}
	}
	& .btn + .dropdown-toggle {
		padding: 8px 13px 6px 9px;
		text-align: center;
	}
	> .btn:focus {
		outline: 0;
	}
}

// buttons holder
// (this will mostly be used on the style guide, but put here just in case)
.buttons-holder {
	.btn {
		margin-bottom: 10px;
	}
}

@media @min-sm {
	.btn {
		&-control {
			font-size: 18px;
			height: 50px;
			line-height: 47px;
			width: 50px;
		}
	}
}

.lightboxes-dropdown-menu {
    max-height: 267px;
    overflow: scroll;
}

.btn-save-form-changes {
	.btn-link;
	color: @brand-primary;
	display: inline-block;
	font-family: @main-font-regular-bold;
	font-size: 17px;
	margin-top: (@grid-gutter-width / 3);
	padding-bottom: 11px;
	text-decoration: underline;

	@media @max-xs {
		width: 100%;
	}
	@media @min-sm {
		margin-top: (@grid-gutter-width / 2);
	}

	&:hover {
		color: @brand-primary;
		text-decoration: none;
	}
}
