.cart-table {
	margin-top: 30px;

	@media @min-sm {
		display: table;
		width: 100%;
	}

	.cart-row {
		@media @max-xs {
			+ .cart-row {
				border-bottom: 1px solid darken(@swatch-14, 5%);
				margin-bottom: (@grid-gutter-width / 2);
				padding-bottom: (@grid-gutter-width / 2);
			}
		}
		@media @min-sm {
			display: table-row;
		}
	}

	.cart-cell {
		overflow: hidden;

		@media @min-sm {
			border-bottom: 1px solid @swatch-14;
			display: table-cell;
			padding: 20px (@grid-gutter-width / 2);
		}

		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
	}

	.cart-cell-pic {
		@media @max-xs {
			margin-bottom: 15px;
		}
	}

	.cart-cell-size, .cart-cell-color, .cart-cell-qty {
		@media @max-xs {
		    float: left;
		    width: 30%;
		}
	}
	.cart-cell-color {
		@media @max-xs {
			padding-left: (@grid-gutter-width / 3);
			padding-right: (@grid-gutter-width / 3);
			width: 50%;
		}
	}
	.cart-cell-qty {
		@media @max-xs {
		    position: relative;
		    top: -8px;
			width: 20%;
		}
	}
	.cart-cell-price {
		@media @max-xs {
			clear: both;
			float: right;
			text-align: right;
		}
	}

	.cart-header {
		@media @max-xs {
			display: none;
		}

		.cart-cell {
			border: none;
			color: @brand-primary;
			font-size: 18px;
			font-weight: bold;
			padding-bottom: 0;
			padding-top: 0;
			position: relative;
			text-transform: uppercase;

			&:after {
				background: @brand-primary;
				content: '';
				display: block;
				height: 3px;
				margin-top: 15px;
				width: 100%;
			}
		}
	}
}
.cart-price {
	width: 166px;
}

.cart-item-pic {
	float: left;
	margin-right: (@grid-gutter-width / 2);
	text-align: center;
	width: 138px;

	@media @min-md {
		margin-right: @grid-gutter-width;
	}

	img {
		max-width: 100%;
	}
}
.cart-item-title {
	color: @text-color;
	font-size: 18px;
}
.cart-item-price {
	font-size: 18px;
}

// Promo code box
.cart-promo-code {
	margin-top: 28px;

	@media @min-md {
		float: left;
	}

	.has-coupon {
		font-size: 16px;

		@media @max-xs {
			text-align: center;
		}
	}

	.form-control {
		@media @max-xs {
			width: 100%
		}
		@media @min-sm {
			margin-right: 10px;
			width: 210px;
		}
	}
	.btn {
		@media @max-xs {
			width: 100%
		}
		@media @min-sm {
			position: relative;
			top: -2px;			
		}
	}
}

// Cart subtotal
.cart-subtotal {
	font-size: 22px;
	font-weight: bold;
	margin-top: 28px;
	text-transform: uppercase;

	@media @max-sm {
		text-align: center;
	}

	@media @min-md {
		float: right;
	}

	.cart-price {
		display: inline-block;
		padding-left: (@grid-gutter-width / 2);

		@media @min-sm {
			margin-left: 100px;
		}
	}
}
.cart-subtotal-label {
	display: inline-block;
}

.credit-cards {
	background: url('/img/credit-cards.png') no-repeat 0 0;
	height: 27px;
    margin-bottom: 14px;
    margin-top: 18px;
	width: 181px;
}

.remove-cart-item-btn {
	background: none;
	border: none;
	font-size: 17px;

	&:before {
		.fa-icon();
		content: @fa-var-trash;
	}
}
