.checkout-forms {
	background: @generic-bg;
	margin-top: 30px;
	padding: 30px 0;

	@media @min-sm {
		margin-top: 75px;
		padding: 75px 0;
	}
}

.checkout-column {
	.make-md-column(4);
	overflow: hidden;

	.form-group {
		margin-bottom: 9px;
	}
}

.checkout-subtitle {
	font-size: 24px;
	margin-bottom: 14px;
	margin-top: 0;

	&.with-margin {
		margin-top: 10px;
	}

	+ .checkbox {
		font-size: 15px;

		@media @max-xs {
			clear: both;
			margin-bottom: 14px;
		}
		@media @min-sm {
			float: right;
			position: relative;
			top: -2px;
		}
	}
}

@media @max-xs {
	.checkout-column + .checkout-column {
		margin-top: 12px;
	}	
}
