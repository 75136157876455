.item-cell {

  &-image-container {
    display: inline-block;
    padding-bottom: 8px;
    position: relative;
    text-align: center;

    @media print {
      align-items: flex-end;
      display: flex;
      min-height: 237px;
    }

    &.premium {
      &:after {
        .circle(22px);
        .fa-icon();
        background: #F15A2A;
        bottom: 16px;
        color: #fff;
        content: "\f005";
        line-height: 22px;
        opacity: 0.8;
        position: absolute;
        right: 8px;
      }
    }
    @media only print {
      &:after {
        display: none;
      }
    }
  }
  &-call-to-action {
    margin-bottom: 7px;

    @media only print {
      display: none;
    }

    .alert-small {
      margin: 10px 0;
    }
  }
  &-title {
    display: block;

    @media print {
      word-break: break-all;
    }
  }
  &-description {
    align-self: stretch;
    font-size: 0.893em;
    line-height: 1.24;
    letter-spacing: .5px;
  }
}

.item-cell-image {
  max-height: none;
  max-width: 100%;
}


@media @min-sm {
  .item-cell {
    vertical-align: top;
    width: 288px;

    &-image-container {
      display: table-cell;
      height: 258px;
      vertical-align: bottom;
      z-index: 0;

      &:active, &:focus {
        outline: none;
      }
    }
  }
  .item-cell-image {
    max-height: 240px;
    max-width: 240px;
  }
}
@media print and @max-sm {
  .item-cell {
    display: inline-block;
    padding: 0 13px 40px;
  }
}
@media print {
  .item-cell {
    width: 24.5%;
  }
}
