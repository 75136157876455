.mini-cart-summary {
	color: @text-color;
	display: block;
	float: right;
	font-size: 16px;
	margin-top: @grid-gutter-width;
	text-align: center;

	&:hover, &:focus, &:active {
		color: #000;
		text-decoration: none;
	}

	@media @min-sm {
		text-align: right;
	}

	strong {
		color: @brand-primary;
	}
}