.carousel {
    margin-bottom: 40px;

    @media @min-lg {
        margin-left: auto;
        margin-right: auto;
        max-width: 1705px;
    }
    &-indicators {
        bottom: -38px;
        z-index: 1;

        li {
            border-color: @gray-dark;

            &, &.active {
                height: 8px;
                margin: 1px 0 0 4px;
                width: 8px;
            }
        }
        .active {
            background: @brand-primary;
            border: none;
        }
    }
    &-controls-container {
        left: 0;
        position: absolute;
        right: 0;
        top: 45%;
        z-index: 1;
    }
    &-control {
        position: static;

        &.right, &.left {
            background: none;
        }
        &.right {
            float: right;
        }
    }
    &-inner {
        @media @min-lg {
            max-height: 492px;
        }
        > .item {
            @media @max-xs {
                min-height: 276px;
                max-height: 276px;
                text-align: center;
            }
            @media @sm-only {
                min-height: 290px;
            }
            @media @min-md {
                min-height: 450px;
            }
            @media @min-lg {
                max-height: 492px;
            }
            img {
                max-width: 100%;
                left: 50%;
                position: relative;
                transform: translateX(-50%);

                @media @min-sm {
                    height: auto;
                    margin-left: auto;
                    margin-right: auto;
                    // max-width: 1705px;
                    width: 100%;
                }
            }
            // > a {
            //   img {
            //     width: 100%;
            //   }
            // }
        }
    }
    &-caption {
        bottom: 0;
        left: 0;
        padding-bottom: 20px;
        right: 0;

        a {
            color: @white-color;
            font-size: 14px;
            font-weight: bold;
            text-decoration: underline;

            &:hover {
                color: @swatch-2;
                text-decoration: none;
            }
        }
    }
    &-big-search-container {
        top: 0;
        left: 0;
        position: absolute;
        right: 0;
        width: 100%;
        height: 100%;
    }
    .big-search {
        text-align: center;
        max-width: 425px;
        margin: auto;
        padding-top: 20px;
	    padding-bottom: 20px;
        position: relative;
        z-index: 1;

        @media @xs-only {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }
}

.home-carousel {
    .carousel-inner {
        @media @max-xs {
            > .item {
                img {
                    max-height: 276px;
                    max-width: none;
                    width: auto;
                }
            }
        }
    }
}

.carousel-indicators {
    display: flex;
    left: 0;
    right: 0;
    width: 100%;
    justify-content: center;
    margin: 0;
    height: 9px;

    li {
        background: @swatch-18;
        flex: none;

        &.active {
            background: @swatch-16;
        }
    }
}

@media @min-sm {
    .carousel {
        margin-bottom: 40px;
        &-indicators {
            li {
                &, &.active {
                    height: 9px;
                    margin: 1px 3px;
                    width: 9px;
                }
            }
        }
    }
}

@media @sm-only {
    .carousel {
        &-big-search-container, &-caption {
            > .container {
                width: 100%;
            }
        }
        .big-search {
            width: 60%;
        }
    }
}

@media @min-md {
    .carousel {
        .big-search {
            width: 100%;
        }
    }
}

@media @min-sm {
    .carousel {
        .big-search {
            padding-top: 120px;
		    padding-bottom: 52px;
        }
    }
}
