.cm-lp-show {
	@media @min-sm {
		.info-box {
			&-wrapper {
				margin-top: 85px;
				margin-bottom: 82px;
			}
		}
	}
	.footer-wrapper {
		// padding-top: 0;
	}
}