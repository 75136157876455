textarea {
	resize: none;
}
.form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	box-shadow: inset 0 1px 2px 0 @form-control-shadow-color;
	border: none;
	font-size: 15px;

	&[disabled], &[readonly], fieldset[disabled] & {
		color: @swatch-7;
	}

	&-feedback {
		height: 13px;
		right: 10px;
		top: 14px;
		width: 13px;
	}
	select& {
		// color: @gray-dark;
		// font-family: @main-font-regular-bold;
		// font-size: 15px;
	}
	textarea& {
		color: @gray-dark;
		padding: 12px 15px;
	}
}

.has-success {
	color: @gray-dark;

	.form-control-feedback {
		color: @swatch-2;
	}
}
.has-error {
	.form-control {
		border: 1px solid @error-color;
		color: @error-color;

		&::placeholder {
			color: lighten(@error-color, 20%);
		}
	}
	.form-control-feedback {
		color: @error-color;
	}
}

.form-message {
	margin-bottom: 14px;
	padding: 8px;

  ul {
    list-style-position: inside;
  }
}

// checkboxes and radio buttons
// (both share practically the same styles)
.checkbox input[type="checkbox"],
.radio input[type="radio"] {
	visibility: hidden;

	// states
	&:checked {
		+ label {
			&:before {
				.input-check-state-style(@checkbox-checked-bg, @checkbox-checked-color);
			}
		}
	}
	&:disabled {
		+ label {
			&:before {
				.input-check-state-style(@checkbox-disabled-bg, @checkbox-disabled-color);
			}
		}
	}
}
.checkbox, .radio {
	label {
		font-family: @main-font-regular-bold;
		padding: 0 0 0 26px;
		position: relative;

		&:before {
			.fa-icon();
			background: @checkbox-default-bg;
			border: 1px solid @checkbox-default-border;
			content: " ";
			height: 18px;
			left: 0;
  			line-height: 18px;
			position: absolute;
			text-align: center;
			top: 0;
			width: 18px;
		}
	}
}

.checkbox, {
	input[type="checkbox"] {
		&:checked + label {
			&:before {
				content: @fa-var-check;
			}
		}
	}
	label {
		&:before {
			border-radius: 2px;
		}
	}
}
.radio {
	input[type="radio"] {
		&:checked + label {
			&:before {
				content: @fa-var-circle;
			}
		}
	}
	label {
		&:before {
			border-radius: 50%;
			font-size: 10px;
		}
	}
}
.form-actions {
	&.text-right {
		.btn {
			margin-left: (@grid-gutter-width / 2);
		}
		@media @max-xs {
			a + .btn {
				margin-top: (@grid-gutter-width / 2);
			}
		}
	}
}

.required {
	label {
		&:after {
			content: "*";
		}
	}
}

.form-row {
	.make-row((@grid-gutter-width / 3));
}
.form-col {
	.make-xs-column(6, (@grid-gutter-width / 3));
}
