.alert {

	&-small {
		.alert;
		font-size: 12px;
		padding: (@alert-padding / 2);

		&.alert-dismissible {
			padding-right: (@alert-padding / 2);

			.close {
				opacity: 0.5;
    			right: -4px;
			    top: -10px;
			}
		}
	}

	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}

	&-title {
		font-family: @main-font-regular-medium;
		font-size: 24px;
		line-height: 26px;
	}

	// alert states
	&-success {
		.button-alert-shadow(@alert-success-border);

		.alert-title, .close {
			color: @alert-success-text !important;
		}
		.close {
			opacity: 1;
		}
	}
	&-info {
		.button-alert-shadow(darken(@swatch-2, 10%));

		.close {
			.opacity(0.1);
		}
		.alert-link {
			color: @white-color;
		}
	}
	&-warning {
		.button-alert-shadow(@brand-primary);

		.alert-title, .close {
			color: @brand-primary;
		}
		.close {
			opacity: 1;
		}
	}
	&-danger {
		.button-alert-shadow(darken(@brand-primary, 10%));

		.close {
			.opacity(0.1);
		}
		.alert-link {
			color: @white-color;
		}
	}

	.close {
		opacity: 0.5;
	}
}

@max-xs {
	.alert {
		.close {
			font-size: 19px;
		}
	}
}

.alert-100 {
	.alert-info;
}
.alert-200 {
	.alert-success;
}
.alert-300 {
	.alert-warning;
}
.alert-400, .alert-500 {
	.alert-danger;
}

.item-info-alert.download-hi-res-alert {
  margin-bottom: 12px;
}
