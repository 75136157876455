.footer-wrapper {
	padding-top: 28px;	
}
.footer {
	background: @swatch-2;
	padding-top: 37px;
	padding-bottom: 37px;
	position: relative;
}
.footer-nav {
	padding-left: 0;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-bottom: 0;
	list-style: none;
	a {
		color: @footer-link-color;
		font-family: @main-font-regular-medium;
		letter-spacing: 1.12px;
		padding: 0 2px;
		@media @max-xs {
			padding: 0 7px;
		}
		&:hover {
			color: lighten(@footer-link-color, 20%);
			text-decoration: none;
		}
	}
}
.footer-nav-container {
	margin-bottom: 20px;
}
.footer-social-nav {
	display: flex;
	justify-content: center;
	margin-bottom: 18px;

	li {
		padding-left: 8px;
		padding-right: 8px;

		@media @sm-only {
			padding-bottom: 9px;
		}
	}
	a {
		border-radius: 6px;
		display: inline-block;
		font-size: 30px;
		height: @footer-social-nav-link-size - 2;
		line-height: @footer-social-nav-link-size - 1;
		text-align: center;
		width: @footer-social-nav-link-size;
		i {
			&:extend(.fa all);
			color: @swatch-17;
		}

		&:hover {
			text-decoration: none;
		}
	}

// Social Media Footer Links

	.sm-orange {
		.button-shadow(@btn-danger-shadow-color);
		background: @btn-danger-bg;

		&:hover {
			.button-shadow(lighten(@btn-danger-shadow-color, 10%));
			background: lighten(@btn-danger-bg, 10%);
		}

	}

	.sm-grey {
		.button-shadow(darken(@sm-grey-bg, 10%));
		background: @sm-grey-bg;
		font-size: 20px;

		&:hover {
			.button-shadow(@sm-grey-bg);
			background: lighten(@sm-grey-bg, 10%);
		}
	}

	.sm-blue {
		.button-shadow(darken(@sm-blue-bg, 10%));
		background: @sm-blue-bg;

		&:hover {
			.button-shadow(@sm-blue-bg);
			background: lighten(@sm-blue-bg, 10%);
		}
	}
}
.footer-social-nav-container {
	margin-bottom: 10px;
}
.copyright {
	color: @copyright-color;
	line-height: 1.167em;
	text-align: center;
	letter-spacing: .88px;

	small {
		font-size: 11px;
	}
}

.site-credits {
	display: block;

	@media @max-xs {
		text-align: center;
	}
	
	@media @min-md {
		display: inline-block;
		margin-left: 8px;
	}

	&-link {
		color: @copyright-color;
		text-decoration: underline;
		padding: 0 3px;

		&:hover {
			color: @footer-link-color;
			text-decoration: none;
		}

		&:focus  {
			color: @footer-link-color;
		}
	}
}

.link-back-top {
	border: 2px solid @back-top-link-color;
	border-radius: 30px;
	bottom: 106px;
	color: @back-top-link-color;
	display: none;
	font-size: 10px;
	height: 28px;
	line-height: 23px;
	position: fixed;
	right: 13px;
	text-align: center;
	width: 28px;

	&:hover {
		border-color: @back-top-link-hover-color;
		color: @back-top-link-hover-color;
	}
}

@media @min-xs-landscape {
	.footer-nav {
		li {
			display: inline-block;

			&:first-child:before {
				display: none;
			}

			&:before {
				background: @swatch-7;
				content: " ";
				display: inline-block;
				height: 14px;
				margin: 0px 10px -2px 8px;
				width: 2px;
			}
		}
	}
}
@media @min-xs-landscape and @max-xs {
	.footer {
		text-align: center;
	}
}
@media @min-sm {
	.footer-wrapper {
		padding-top: 40px;
	}
}
@media @sm-only {
	.footer {
		> .container {
			width: 100%;
		}
	}
}
@media @min-md {
	.footer-wrapper {
		padding-top: 65px;
	}
}
