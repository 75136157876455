.search-box {
	.form-control {
		color: @swatch-7;
		border: 2px solid @swatch-16;
		box-shadow: none;
	}
	&-button {
		background: none;
		border: none;
		color: #000;
	}
	&-advanced-link {
		color: @swatch-8;
		font-family: @main-font-regular-medium;
		font-size: 15px;
	}
}

@media @max-sm {
	.search-box {
		.form-control {
			padding-left: 60px;
		}
		&-wrapper {
			position: relative;
		}
		&-button {
			font-size: 23px;
			left: 16px;
			position: absolute;
			top: 2px;
		}
		&-advanced-link {
			border: 1px solid @gray-dark;
			border-width: 1px 0;
			display: block;
			line-height: 20px;
			padding: 10px 15px;
		}

		form {
			width: 100%;
		}
	}
}
@media @max-sm {
	.search-box {
		&-button {
			padding-left: 6px;
			padding-top: 2px;
			padding-right: 6px;
		}
	}
}
@media @min-md {
	.search-box {
		// the below min-width should be used when the advanced search link is back
		// min-width: 360px; 
		float: right;

		.search-form {
			float: left;
		}
		.form-group {
			margin: 0;
		}
		.form-control {
			background: @search-box-md-bg;
			border-radius: @border-radius-base 0 0 @border-radius-base;
			float: left;
			height: 34px;
			width: 161px;
			// border: 2px solid @swatch-16;
			border-right: 0;
			margin-right: -2px;
		}
		&-button {
			.border-right-radius(@border-radius-base);
			background: @search-box-md-bg;
			font-size: 19px;
			height: 34px;
			width: 34px;
			border: 2px solid @swatch-16;
			border-left: 0;
		}
		&-wrapper {
			width: 204px;
		}
		&-advanced-link {
			display: inline-block;
			float: left;
			margin: 6px 0 0 12px;
		}
	}
}