.cart-summary {
	.make-md-column(4);
	.make-md-column-push(8);
	margin-top: 30px;

	@media @min-sm {
		margin-top: 55px;
	}
}

.cart-summary-line {
	.make-row();
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 20px;
	text-transform: uppercase;

	@media @min-sm {
		margin-bottom: 35px;
	}

	&.is-total {
		color: @brand-primary;
	}
}
.cart-summary-label, .cart-summary-price {
	.make-sm-column(6);
}
.cart-summary-label {
	.make-xs-column(5);
}
.cart-summary-price {
	.make-xs-column(7);
	text-align: right;

	.is-total & {
		font-size: 24px;
		margin-top: -6px;
	}
}
.cart-summary-button {
	.btn;
	// .btn-danger;
	.btn-lg;
	.btn-block;
	// .button-shadow(@btn-danger-shadow-color);
	font-size: 24px;
	// text-transform: uppercase;
}