.top-banner-container {
	margin-left: auto;
	margin-right: auto;
	margin-top: calc(@grid-gutter-width / 2);
	width: 100%;
	aspect-ratio: 1740 / 660;

	& .main-title {
		aspect-ratio: 0;
	}

	@media @min-sm {
		margin-top: @grid-gutter-width;
	}

	img {
		max-width: 100%;
	}
	+ .page-content {
		margin-top: @grid-gutter-width * 1.5;
	}
}

.generic-section .top-banner-container {
	aspect-ratio: 0;
}

.page-content {
	a {
		.link-primary;
	}
	h1 {
		margin-bottom: 20px;
	}
	b, strong {
		font-family: @main-font-regular-medium;
		font-weight: normal;
	}
}

.highlight-content {
	font-family: @main-font-regular-bold-italic;
	font-size: 14px;
	margin: 20px auto;
	
	&:first-child {
		margin-top: 20px;
	}

	& + & {
		&:before {
			background: darken(@gray-light, 10%);
			content: "";
			display: block;
			height: 1px;
			margin: 0 auto 20px;
			width: 100%;
		}
	}
}

.hidden-bar {
	display: none;
}