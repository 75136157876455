// some specific pages has a different background
body {
	&.index, &.page, &.image-advanced-search,
	&.users-account, &.users-signup, &.users-signin,
	&.users-forgot-password, &.cm-lp-show,
	&.buy-prints-show {
		background: #fafafa;
	}

	&.image-index {
		background: #fafafa;
		.main {
			min-height: 285px;

			@media @min-sm {
				min-height: 315px;
			}
		}
	}
}

.narrow.container {
	@media @min-md {
		max-width: 900px !important;
	}
}

.separator {
	background: @brand-primary;
	content: "";
	display: inline-block;
	height: 12px;
	margin-left: 11px;
	margin-right: 11px;
	position: relative;
	top: 1px;
	width: 2px;
}

// errors holder on top of most pages
.errors-holder, .generic-section {
	margin-top: 24px;
}

.spinner {
	background: @white-color;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
	text-align: center;
	transition: .5s;
    top: 0;

	.fa {
		.fa-spin;
		font-size: 22px;
	    margin-top: -11px;
	    position: absolute;
	    top: 50%;

		&:before {
			content: "\f021";
		}
	}
}

.link-primary {
	color: darken(@swatch-2, 15%);
}

a {
	&:after {
		@media only print {
			display: none;
		}
	}
}

a.blue-backgorund {
	color: @white-color;
	font-weight: bold;

	&:hover {
		color: @white-color;
		font-weight: bold;
	}
}

a.orange {
	color: @brand-primary;
	font-weight: bold;

	&:hover {
		color: @brand-primary;
		font-weight: bold;
	}
}

// @media all {
//   .break-before, .break-after {
//     display: none;
//   }
// }
@media print {
  .break-after {
    display: block;
    page-break-after: always;
    position: relative;
    width: 100%;
  }
  .break-before {
    display: block;
    page-break-before: always;
    position: relative;
    width: 100%;
  }
}

@media @min-lg {
	.container {
		width: 1490px !important;
		max-width: 100%;
	}
}

.bg-light-gray {
  background: #fafafa;
}

.br-0 {
  border-right: 0 !important;
}

.bl-0 {
  border-left: 0 !important;
}

.ico-close {
	position: relative;
	display: inline-block;
	--size: 30px;
	width: var(--size);
	height: var(--size);

	&::before,
	&::after {
		content: "";
		width: 100%;
		height: 1px;
		background: @swatch-16;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	}

	&::before {
		transform: rotate(45deg);
	}

	&::after {
		transform: rotate(-45deg);
	}
}

.d-flex {
	display: flex;
	flex-wrap: wrap;
}

.align-center {
	align-items: center;
}

.mr-1 {
	margin-right: .5rem;
}

.container-xl {
	width: 1170px !important;
	max-width: 100%;
}