.pagination {
	// .button-shadow(@pagination-border);
	margin: 0 4px;

	@media only print {
		display: none;
	}

	&-container {
		margin-top: 40px;
		text-align: center;
	}

	&-control {
		// .button-shadow(darken(@gray-dark, 10%));
		color: @white-color;
		background: @gray-dark;
		border: none;
		border-radius: 4px;
		font-family: @main-font-regular-bold;
		font-size: 15px;
		font-weight: bold;
		margin-top: -28px;
		padding: 6px 14px 7px;
		text-align: center;

		&:hover {
			background: lighten(@gray-dark, 5%);
		}
		.fa {
			border: 2px solid @white-color;
			border-radius: 50%;
			color: @white-color;
			font-size: 10px;
			line-height: 15px;
			height: 18px;
			margin: 0;
			text-align: center;
			width: 18px;
		}
		&-previous {
			.pagination & {
				border-radius: @border-radius-base 0 0 @border-radius-base;
			}
			.fa {
				margin-left: 0;
				padding-right: 1px;
			}
		}
		&-next {
			.pagination & {
				border-radius: 0 @border-radius-base @border-radius-base 0;
			}
			.fa {
				margin-right: 0;
				padding-left: 1px;
			}
		}
	}

	li {
		> a, > span {
			// border-width: 0 0 0 1px;
			font-family: @main-font-regular-medium;
			font-size: 15px;
		}

		&.active > span {
			padding: 8px 15px 6px;
		}
		&.disabled > span {
			padding: 8px 7px 6px;
		}
		> a {
			padding: 7px 17px 6px;
		}
		&:first-child {
			a {
				border-left: none;
			}
		}
	}
}

@media @min-sm {
	.pagination {
		&-control {
			.fa {
				margin: 0 7px;
			}
		}

		li {
			> a, &.active > span {
				padding: 8px 19px 6px;
			}
			&.disabled > span {
				padding: 8px 10px 6px;
			}
		}
	}
}