.big-search {
	display: flex;
	flex-direction: column;
	height: 100%;
	&-title {
		color: @white-color;
		font-size: 36px;
		line-height: .93;
		margin-bottom: 10px;
		flex: auto;
		text-transform: uppercase;
	}
	.form-control {
		.border-right-radius(@border-radius-base) !important;
		font-size: 14px;
		text-overflow: ellipsis;
		width: 97.5%;
		background: @swatch-17;
		border: 2px solid @swatch-19;
		border-right: 0;
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
		box-shadow: none;
		height: 35px;
		color: @swatch-7;
		.placeholder(@swatch-7);
		letter-spacing: .34px;
	}
	.btn-search {
		.border-left-radius(@border-radius-base) !important;
		background: @swatch-17;
		border: 2px solid @swatch-19;
		border-left: 0;
		box-shadow: none;
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
		line-height: 20px;
		padding: 2px 8px 3px 10px;
    font-size: 24px;

		.fa {
			color: @swatch-18;
		}
	}

	.input-group-btn {
		display: flex;
		flex: auto;
		width: auto;
	}

	.input-group {
		display: flex;
		height: 35px;
	}
}

@media @min-sm {
	.big-search {

		.form-control {
			font-size: 17px;
		}
		&-title {
			font-size: 60px;
		}
	}
}
@media @sm-only {
	.big-search {
		&-title {
			font-size: 31px;
		}
	}
}