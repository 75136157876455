// body has class "index"
.index {
	.info-box {
		&-wrapper {
			margin-top: 60px;
		}
	}

	@media @min-sm {
		.info-box {
			&-wrapper {
				margin-top: 92px;
			}
		}
	}
}