.products {
  .make-row();
  margin-top: 40px;
  position: relative;

  .product {
    .make-xs-column(12);
    .make-sm-column(6);
    .make-md-column(3);
    margin-bottom: @grid-gutter-width;

    .form-message {
      display: none !important;
    }
  }
  .product-inner {
    border: 1px solid #f1f2f4;
    padding: 25px 16px;

    @media @min-sm {
      height: 702px;
    }
    @media @md-only {
      height: 756px;
    }
  }
  .product-image {
    text-align: center;

    @media @min-sm {
      height: 300px;
      position: relative;
      width: 100%;
    }

    img {
      max-width: 100%;

      @media @min-sm {
        margin: 0 auto;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
      }
    }
  }

  .product-desc-container {
    @media @min-sm {
      height: 150px;
    }
    @media @sm-only {
      height: 110px;
    }
  }
  .product-title {
    color: @text-color;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;

    a {
      color: @text-color;
      font-weight: 400;
    }
  }
  .product-desc {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .product-price {
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    margin-top: 4px;

    @media @max-xs {
      display: block;
      margin-bottom: 12px;
      text-align: center;
    }

    @media @min-sm {
      float: left;
      max-width: 42%;
    }

    .currency {
      font-size: 14px;
    }
  }

  .login-to-add-btn {
    &:before {
      content: @fa-var-sign-in;
    }
  }

  .selectize-input {
    .btn;
    .button-shadow(@btn-default-shadow-color);
    border: 1px solid #dcdcdc;
    line-height: 29px;
    padding-left: 20px;
    text-align: left;
  }
  .selectize-dropdown .option {
    color: @swatch-2;
  }
}

.product-cta-btn {
  .btn;
  // .btn-danger;
  // .button-shadow(@btn-dange/r-shadow-color);
  // font-size: 14px;
  // padding-left: 8px;
  // padding-right: 8px;
  // text-transform: uppercase;

  @media @max-xs {
    width: 100%;
  }

  @media @min-sm {
    float: right;
  }

  &:before {
    .ico-font();
    content: @fa-var-shopping-cart;
    margin-right: 5px;
  }
}

.floating-checkout {
  opacity: 0;
  overflow: hidden;

  &.is-floating {
    height: 40px;
    opacity: 1;
  }
  &-btn {
    .btn;
    // .btn-danger;
    // .button-shadow(@btn-danger-shadow-color);
    float: right;
    color: @white-color;
    background: @swatch-4;
    border-color: @swatch-4;
    // text-transform: uppercase;

    &:focus,
    &:hover,
    &:active {
      background: @white-color;
      color: @swatch-4;
      border-color: @swatch-4;
    }

    .is-floating & {
      bottom: 115px;
      position: fixed;
      right: 20px;
      text-transform: uppercase;
      z-index: 10;

      @media @max-sm {
        padding: 8px 16px 5px;
      }
    }
  }
}

// Product modal
.product-modal-body {
  .form-message.bg-success {
    display: none !important;
  }

  .selectize-input {
    .btn;
    .button-shadow(@btn-default-shadow-color);
    border: 1px solid #dcdcdc;
    line-height: 29px;
    padding-left: 20px;
    text-align: left;
  }
  .selectize-dropdown .option {
    color: @swatch-2;
  }
}

// related products
.related-products-heading {
  margin-top: @grid-gutter-width;

  @media @min-sm {
    margin-top: (@grid-gutter-width / 2);
  }
}
.products.related-products {
  .product-inner {
    @media @min-sm {
      height: 540px;
    }
  }
}


