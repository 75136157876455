.datepicker-dropdown {
	background: @gray-lighter;
}

.date {
	.input-group-addon {
		background: @white-color;
		color: darken(@swatch-2, 20%);

		&:hover {
			background: lighten(@swatch-2, 20%);
		}
	}
}