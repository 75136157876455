.is-framed {
	img {
		background: #fff;
		border: 15px solid #000;
		box-shadow: inset 0px 0px 14px rgba(0,0,0,.8);
		padding: 30px;

		@media @min-md {
			border: 22px solid #000;
			max-width: 80% !important;
			padding: 50px;
		}
	}
}