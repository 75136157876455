.main-box {
	margin-bottom: 85px;
	&-lead {
		font-size: 18px;
	}
	.big-search {
		margin: 40px auto 0;
		margin-top: 40px;
		max-width: 555px;
	}
}

@media @min-sm {
	.main-box {
		margin-bottom: 126px;
		margin-top: 88px;
		> .container {
			padding: 0 7%;
		}
		&-title {
			font-size: 65px;
		}
		&-lead {
			font-size: 22px;
		}
		.big-search {
			margin-top: 45px;
		}
	}
}