.info-box {
	text-align: center;
	img {
		width: 100%;
		border-radius: @border-radius-base @border-radius-base 0 0;
	}
	&-title {
		color: @gray-darker;
		font-size: 30px;
		margin-top: 15px;
		margin-bottom: 9px;
		letter-spacing: .9px;
	}
	&-description {
		color: @gray-darker;
		padding: 0;
		letter-spacing: 1.12px;
		font-size: 16px;
		line-height: 1.25;
	}
	.btn-minimal {
		margin-top: 10px;
	}
}

@media @min-md {
	.info-box {
		&-title {
			font-size: 43px;
			margin-top: 31px;
		}
		.btn-minimal {
			margin-top: 22px;
		}
	}
}

@media @min-lg {
	.info-box {
		&-description {
			padding: 0 40px;
		}
	}
}

@media @min-sm {
	.info-box {
		img {
			margin: auto;
			width: auto;
		}
	}
}

@media @max-sm {
	.info-box {
		margin-bottom: 30px;
		&-wrapper {
			width: 100%;
		}
	}
}
