// figure image
.figure-object {
	border-radius: 4px;
	max-width: 100%;
}
.figure-caption {
	color: @figure-caption-color;
	font-family: @main-font-regular-italic;
}

// figure
.figure {
	.figure-object {
		margin-bottom: 11px;
	}
}

@media @min-sm {
	.figure {
		.figure-object {
			margin-bottom: 26px;
		}
	}
}
