.fieldset {
	margin-bottom: 15px;

	&-heading {
		border-bottom: 1px solid lighten(@swatch-8, 10%);
		padding-bottom: 1px;

		strong {
			color: @gray-darker;
			font-size: 16px;
		}

		a {
			color: @brand-primary;
			font-family: @main-font-regular-bold;
		}
	}
	&-content {
		margin-top: 6px;
	}
	&-table {
		background: none;
		width: 100%;

		th, td {
			padding-bottom: 2px;
			padding-left: 8px;
		}

		th {
			font-size: 11px;
			text-transform: uppercase;

			// &:first-child {
			// 	padding-left: 0;
			// }
		}
		td {
			padding-top: 2px;
		}

		tr:nth-child(odd) td {
			background: @gray-lighter;
		}

		a {
			color: @brand-primary;
			font-size: 13px;
			
			&:after {
				background: @gray-dark;
				content: " ";
				display: inline-block;
				height: 10px;
				margin: 0 3px 0 6px;
				width: 1px;
			}

			&:last-child:after {
				display: none;
			}
		}
		.btn {
			margin: 6px 0;
		}
	}

	&-fields {
		margin-bottom: 30px;

    	label {
    		font-size: 13px;
    	}
	}
}