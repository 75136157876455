.lightbox-item {
	margin-bottom: (@grid-gutter-width / 2);

	@media @max-sm {
		& + & {
			border-top: 1px solid lighten(@swatch-8, 20%);
			padding-top: (@grid-gutter-width / 2);
		}
	}
	@media @min-md {
		margin-bottom: 45px;
	}
	
	&-heading {
		.clearfix;

		@media @min-md {
			border-bottom: 1px solid lighten(@swatch-8, 10%);
			pading-bottom: 1px;
		}
	}
	&-title {
		@media @max-sm {
			margin-top: 0;
		}
		@media @min-md {
			float: left;
			font-size: 28px;
		}

		> a {
			color: @brand-primary;

			@media @max-sm {
				display: block;
				margin-bottom: 8px;
			}
		}
	}

	&-actions {
		@media @max-sm {
			.dropdown-menu {
				left: 0;
				right: auto;
			}
		}
		@media @min-md {
			float: right;
			margin-top: 24px;
		}

	    &-btn {
			.btn;
			.btn-primary;
		    font-size: 14px;
		    padding: 4px 12px;
				border-color: @swatch-16;

				&:hover,
				&:focus,
				&:active {
					border-color: @swatch-16;
				}

		    &.lightbox-files-badge {
		    	line-height: 1.35;
		    	padding: 4px 12px !important;

		    	@media @max-sm {
		    		margin-left: 0;
		    	}

					&.lightbox-files-badge {
						background: transparent;
						border: 2px solid @swatch-16;
						pointer-events: none;

						&:hover {
							background: transparent;
						}
					}

		    	&, &:hover {
						background: @gray-light;
						color: @gray-dark;
		    	}

					.badge {
						color: @swatch-16;
					}
		    }
	    }
	}
	.label {
		margin-left: 6px;
		position: relative;
		top: -1px;
		
		@media @max-sm {
			margin-left: 0;
		}
	}
}

.lightbox-detail {
	.label {
		position: relative;
		top: -2px;
	}
}

.lightbox-preview {
	.make-sm-column(2.4, (@grid-gutter-width / 3));
	height: 130px;
	overflow: hidden;

	@media @min-md {
		height: 165px;
	}

	img {
		max-height: none;
		max-width: none;
	}

	&.no-image {
		img {
			max-width: 100%;
		}
	}

	&s {
		.make-row((@grid-gutter-width / 3));

		@media @max-xs {
			display: none;
		}
	}

}
.lightbox-files-badge {
	.btn;
	.btn-primary;
	cursor: default;
	margin-left: 14px;
	padding-left: (@grid-gutter-width / 2);
	padding-right: (@grid-gutter-width / 2);
	white-space: normal;

	&:hover {
		background: @gray-dark;
	}
}

.lightboxes-dropdown-menu {
	&.alert-success {
		a {
			color: darken(@swatch-2, 15%);
			padding-left: 0;

			&:hover {
				background: none;
			}
		}
	}
}
