@fonts-path: "../fonts";

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on August 4, 2014 */
// @font-face {
//     font-family: 'dincompregular';
//     src: url('@{fonts-path}/dincomp-webfont.eot');
//     src: url('@{fonts-path}/dincomp-webfont.eot?#iefix') format('embedded-opentype'), url('@{fonts-path}/dincomp-webfont.woff') format('woff'), url('@{fonts-path}/dincomp-webfont.ttf') format('truetype'), url('@{fonts-path}/dincomp-webfont.svg#dincompregular') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'dincomp-boldregular';
//     src: url('@{fonts-path}/dincomp-bold-webfont.eot');
//     src: url('@{fonts-path}/dincomp-bold-webfont.eot?#iefix') format('embedded-opentype'), url('@{fonts-path}/dincomp-bold-webfont.woff') format('woff'), url('@{fonts-path}/dincomp-bold-webfont.ttf') format('truetype'), url('@{fonts-path}/dincomp-bold-webfont.svg#dincomp-boldregular') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'dincomp-bolditaregular';
//     src: url('@{fonts-path}/dincomp-boldita-webfont.eot');
//     src: url('@{fonts-path}/dincomp-boldita-webfont.eot?#iefix') format('embedded-opentype'), url('@{fonts-path}/dincomp-boldita-webfont.woff') format('woff'), url('@{fonts-path}/dincomp-boldita-webfont.ttf') format('truetype'), url('@{fonts-path}/dincomp-boldita-webfont.svg#dincomp-bolditaregular') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'dincomp-itaregular';
//     src: url('@{fonts-path}/dincomp-ita-webfont.eot');
//     src: url('@{fonts-path}/dincomp-ita-webfont.eot?#iefix') format('embedded-opentype'), url('@{fonts-path}/dincomp-ita-webfont.woff') format('woff'), url('@{fonts-path}/dincomp-ita-webfont.ttf') format('truetype'), url('@{fonts-path}/dincomp-ita-webfont.svg#dincomp-itaregular') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'dincomp-mediumregular';
//     src: url('@{fonts-path}/dincomp-medium-webfont.eot');
//     src: url('@{fonts-path}/dincomp-medium-webfont.eot?#iefix') format('embedded-opentype'), url('@{fonts-path}/dincomp-medium-webfont.woff') format('woff'), url('@{fonts-path}/dincomp-medium-webfont.ttf') format('truetype'), url('@{fonts-path}/dincomp-medium-webfont.svg#dincomp-mediumregular') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'dincomp-mediumitaregular';
//     src: url('@{fonts-path}/dincomp-mediumita-webfont.eot');
//     src: url('@{fonts-path}/dincomp-mediumita-webfont.eot?#iefix') format('embedded-opentype'), url('@{fonts-path}/dincomp-mediumita-webfont.woff') format('woff'), url('@{fonts-path}/dincomp-mediumita-webfont.ttf') format('truetype'), url('@{fonts-path}/dincomp-mediumita-webfont.svg#dincomp-mediumitaregular') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

@font-face {
    font-family: 'dmsans-regular';
    src: url('@{fonts-path}/dmsans-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'dmsans-italic';
    src: url('@{fonts-path}/dmsans-italic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'dmsans-medium';
    src: url('@{fonts-path}/dmsans-medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'dmsans-bold';
    src: url('@{fonts-path}/dmsans-bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'dmsans-bold-italic';
    src: url('@{fonts-path}/dmsans-bolditalic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'bellefair-regular';
    src: url('@{fonts-path}/bellefair-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'icomoon';
    src: url('@{fonts-path}/icomoon.woff?v=3f31cc7231f8ae8e4e90e65efc253eae') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: block;
}
