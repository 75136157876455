.photographer {
	&-sidebar {
		position: relative;
		@media @min-sm {
			margin-top: 20px;
		}
		.panel-heading {
			padding: 0;

			button {
				appearance: none;
				background: none;
				border: none;
				display: block;
				padding: 10px 15px;
				text-align: center;
				width: 100%;

				&:hover, &:focus, &:active {
					outline: none;
					text-decoration: none;
				}
			}
		}

		.list-group-item {
			padding: 0;

			a {
				color: @brand-primary;
				display: block;
				padding: 10px 15px;

				&:hover {
					background: lighten(@brand-primary, 45%);
					text-decoration: none;
				}
			}
		}
	}
	&-group {
		.button-shadow(@btn-default-shadow-color);
		border-radius: @border-radius-base;
		overflow: hidden;
		
		.panel {
			border-radius: 0;
			border-color: @gray-light;
			border-width: 0 1px;

			&:first-child {
				button {
					.border-top-radius(@border-radius-base);
					border-top: 1px solid @gray-light;
				}
			}

			&:last-child {
				button {
					.border-bottom-radius(@border-radius-base);
					border-bottom: 1px solid @gray-light;
				}
			}

			+ .panel {
				margin-top: 0;
				.panel-heading {
					border-top: 1px solid @gray-light;
					// border-width: 1px 1px 0;
				}
			}
		}
	}
	&-content {
		position: relative;
		@media @min-sm {
			.spinner {
				margin-top: 20px;
			}
		}
	}
}