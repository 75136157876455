// swatch item
.swatch-item {
	height: 50px;
	width: 70px;
}
.swatch {
	&-1 {
		background: @swatch-1;
	}
	&-2 {
		background: @swatch-2;
	}
	&-3 {
		background: @swatch-3;
	}
	&-4 {
		background: @swatch-4;
	}
	&-5 {
		background: @swatch-5;
	}
	&-6 {
		background: @swatch-6;
	}
	&-7 {
		background: @swatch-7;
	}
	&-8 {
		background: @swatch-8;
	}
	&-9 {
		background: @swatch-9;
	}
	&-10 {
		background: @swatch-10;
	}
}

// swatch group
li.swatch-group {
	margin: 0 0.45% 10px 0.45%;
	padding: 0;
}
.swatch-group-row {
	margin: 0;
}
.swatch-group-details {
	font-size: 12px;
	margin: 3px 0 0;

	strong {
		display: block;
		font-family: @main-font-regular-bold;
	}
}

// swatches
.swatches {
	margin: 0;
}