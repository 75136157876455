.no-results-content {
    text-align: left;
    
    .container {
        @media @min-lg {
            padding-left: 225px;
            padding-right: 225px;
        }
    }
    
    .h2 {
        @media @min-sm {
            margin-top: 0;
        }
        @media @max-sm {
            br {
                display: none;
            }
        }
    }
}
