.checkout-cta {
	background: @brand-primary;
	padding-bottom: (@grid-gutter-width / 2);
	padding-top: (@grid-gutter-width / 2);
	text-align: center;

	.checkout-btn {
		.btn;
		// .btn-primary;
		// .button-shadow(darken(@brand-primary, 10%));
		background: @white-color;
		border-color: @white-color;
		font-size: 24px;
		text-transform: uppercase;
		color: @swatch-9;

		&:hover,
		&:focus,
		&:active {
			background: @swatch-6;
			border-color: @swatch-6;
			color: @swatch-9;
		}
		
		@media @min-sm {
			min-width: 360px;
		}

		&:before {
			.ico-font();
			content: @fa-var-shopping-cart;
			margin-right: 7px;
		}
	}
}
