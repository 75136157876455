h1, .h1,
h2, .h2 {
	&.bold {
		font-family: @main-font-regular-bold;
	}
}

blockquote {
	color: @blockquote-main-color;
	line-height: 1.05;

	p, q {
		font-size: 26px;
	}
}

b, strong {
	font-family: @main-font-regular-bold;
}

h6, .h6 {
	text-transform: uppercase;
}

.lead {
	font-size: 24px;
	line-height: 26px;
}

.main-title {
	margin-bottom: 20px;

	@media @min-sm {
		margin-bottom: 35px;
	}
}

@media @min-sm {
	h1, .h1 {
		font-size: 34px;
		&.bold {
			font-size: 56px;
		}
	}
	h2, .h2 {
		font-size: 27px;
	}
	h3, .h3,
	h4, .h4,
	h5, .h5 {
		font-size: 28px;
	}
}