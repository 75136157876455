.items-list {
	margin-top: 30px;

	@media @min-sm {
		text-align: center;
	}
	@media @min-md {
		max-width: 1490px;
		width: 100% !important;
	}

  @media print {
    display: flex;
    flex-wrap: wrap;
  }

	.item-cell {
      padding-bottom: 18px;
      text-align: left;
      max-width: 300px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;

    @media @min-sm {
      padding: 0 13px 40px;
      display: inline-block;
    }

    @media print {
      display: flex;
      flex-direction: column;
      height: 50vh;
    }
	}
}
