// here are all elements/modules inside the item-detail section

.item-detail {
	@media @max-xs {
		max-width: 450px
	}
	@media @min-md {
		max-width: 970px;
	}

	&-modal {
		.modal-dialog {
			margin: 10px auto;
			width: 320px;

			@media @min-sm {
				width: 638px;
			}
			@media @min-md {
				margin: 30px auto;
				width: 1008px;
			}
		}
		.modal-footer {
			display: none;
		}
	}
}

.item-detail-inner {
  &.loading {
    // align-items: center;
    // display: flex;
    // justify-content: center;

  }
}

.item-image {
	img {
		max-width: 100%;
	}
	&-inner {
		background: @gray-light;
		margin-bottom: 14px;
    position: relative;
		text-align: center;

    @media @min-sm {
      &:hover {
        .prev-image, .next-image {
          opacity: 0.85;

          &:hover {
            opacity: 1;
          }


          &[disabled] {
            color: #ccc;
            opacity: 0.4;
          }
        }
      }
    }

    .prev-image, .next-image {
      align-items: center;
      background: transparent;
      border: 2px solid;
      border-radius: 50%;
      box-shadow: 0 3px 2px rgba(0, 0, 0, .5);
      color: #fff;
      display: flex;
      font-size: 17px;
      height: 45px;
      justify-content: center;
      margin-top: -18px;
      opacity: 0;
      position: absolute;
      top: 50%;
      transition: opacity .3s;
      width: 45px;
      z-index: 2;

      &:hover {
        text-decoration: none;
      }

      .fa {
        text-shadow: 0 3px 2px rgba(0, 0, 0, .5);
      }
    }

    .prev-image {
      left: 15px;
      .fa {
        margin-left: -3px;
      }
    }
    .next-image {
      right: 15px;
      .fa {
        margin-right: -3px;
      }
    }

		&.premium {
			position: relative;

			&:before {
				.circle(22px);
				.fa-icon();
				background: #F15A2A;
				bottom: 12px;
				color: #fff;
				content: "\f005";
				line-height: 22px;
				opacity: 0.8;
				position: absolute;
				right: 12px;
			}
		}
	}
	&-actions {
		margin-bottom: 12px;

		@media @max-xs {
			text-align: center;
		}

		.separator {
			@media @max-md {
				display: none;
			}
		}

		.btn-minimal-danger {
			font-family: @main-font-regular-bold;
			font-size: 13px;
			text-transform: uppercase;
			color: @swatch-4;
			border: 0;
			padding: 0;

			&:hover,
			&:focus {
				background: transparent;
				opacity: .7;
			}
		}

		.fa {
			font-size: 18px;
		}

		.alert-small {
			margin: 10px 0;
		}
	}
	&-caption {
		color: @gray-darker;
		font-size: 24px;
		margin: 0 0 25px;
		font-family: @main-font-regular-medium;
	}
}

.item-info {
	font-size: 13px;

	&-cta {
		display: inline-block;
		margin-bottom: 20px;
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 10px;

		@media @min-sm {
			grid-template-columns: 1fr;
			grid-gap: 10px 20px;
		}

		@media @min-lg {
			grid-template-columns: 1fr 1fr;
		}

		.btn {
			text-transform: none;
			font-size: 15px;
			letter-spacing: .3px;
			font-family: @main-font-regular;
			width: 100%;

			@media @max-xs {
				text-align: center;
			}
			@media @min-sm {
				text-align: left;
				white-space: normal;
			}
			.fa {
				font-size: 19px;
				margin-top: 3px;
				margin-left: auto;
			}
		}
		a.btn, .btn-group {
			// margin-bottom: 8px;
			width: 100%;
			min-width: 1px;

			@media @sm-only {
				// width: 174px;
			}
		}
		> * {
			&:nth-child(even) {
				float: right;
			}
			&:nth-child(odd) {
				float: none;
				// this is floated only with JS enabled (check .js class)
			}
			@media @max-sm {
				font-size: 14px;
			}
			@media @sm-only {
				width: 100% !important;
			}
		}
		.btn-group {
			.btn {
				border-radius: @border-radius-base !important;
				width: 100%;

				@media @max-sm {
					font-size: 14px;
				}
			}
		}
	}
	&-keywords {
		margin-bottom: 22px;
		letter-spacing: .5px;

		// @media @sm-only {
		// 	margin-bottom: 50px;
		// }

		strong {
			color: @swatch-8;
		}
		a {
			color: @gray-dark;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
	&-specs {
		letter-spacing: .5px;
		// @media @sm-only {
		// 	clear: both;
		// 	margin-top: 110px;
		// }
		@media @max-sm {
			display: none;
		}
		dt, dd {
			line-height: 1.2;
		}
		dt {
			color: @swatch-2;
			float: left;
			padding-right: 10px;
			text-align: left;
			text-transform: uppercase;
			width: 42%;
			font-family: @main-font-regular-bold;
		}
		dd {
			margin: 0 0 16px;
			overflow: hidden;
		}
		a {
			color: @gray-dark;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
		&.clone {
			// .make-xs-column(12);
			display: block;

			@media @min-md {
				display: none;
			}
		}
	}
	&-highlight {
		color: @swatch-2;
	}
	&-alert {
		.alert;
		.alert-info;
		margin-bottom: 0;
		padding-right: 35px;
		position: relative;

		&-close {
			background: none;
			border: none;
			color: #fff;
			font-size: 18px;
			position: absolute;
			right: 12px;
			top: 9px;

			@media @min-sm {
				font-size: 15px;
			}

			&:focus, &:active {
				outline: none;
			}

			&:before {
				.fa-icon();
				content: "\f00d";
			}
		}
	}
}

.image-form-container {
	border-bottom: 1px solid @gray-light;
	margin-bottom: 20px;
	padding-bottom: 22px;

	.image-form-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.item-image-inner {
  &:after {
    background: rgba(0,0,0,.7);
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
  }
  .loading &:after {
    opacity: 1;
    visibility: visible;
  }
  .loading-spinner {
    color: #fff;
    margin-left: -12px;
    margin-top: -10px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 4;
  }
}

.js {
	.item-info {
		&-cta {
			> * {
				&:nth-child(odd) {
					float: left;
				}
			}
		}
	}
}
