.breadcrumb {
	.button-shadow(darken(@gray-darker, 10%));
	background: @gray-darker;
	font-family: @main-font-regular-bold;
	overflow: hidden;

	a, .active {
		padding: 8px 16px 5px 28px;
	}

	a {
		background: @gray-light;
		color: @gray-darker;
		display: block;
		position: relative;

		&:hover {
			color: @swatch-2;
			text-decoration: none;
		}

		&:after {
			background: @gray-light;
			border: solid @gray-darker;
			border-radius: 3px;
			border-width: 2px 2px 0 0;
			content: " ";
			display: inline-block;
			height: 24px;
			position: absolute;
			right: -12px;
			top: 5px;
			transform: rotate(45deg) skew(10deg, 10deg);
			width: 24px;
			z-index: 2;
		}
	}

	li {
		float: left;
		font-size: 15px;

		+ li:before {
			display: none;
		}
		+ li a {
			padding: 8px 16px 5px 28px;
		}

		&:first-child {
			&.active {
				padding-left: 19px;
			}
		}
		+ .active {
			color: @brand-primary;
		}
	}
	
}