.nav-pills {
	> li {
		> a {
			color: @gray-dark;
			font-family: @main-font-regular-bold;
			font-size: 15px;
			margin-bottom: 5px;
			padding: 9px 18px 7px;

			&:hover {
				background: @gray-light;
				.button-shadow(@swatch-7);
			}
			
			.badge {
				font-family: @main-font-regular-medium;
				font-size: 15px;
				line-height: 15px;
				margin: 1px 0 0 58px;
				padding: 1px 10px 2px;
			}
		}
		&.active {
			a {
				.button-shadow(@swatch-10);
			}
		}
		&.disabled {
			a {
				&:hover {
					background: none;
					box-shadow: none;
				}
			}
		}
	}
	.dropdown-toggle {
		background: none;
		color: @gray-dark;

		&:hover {
			background: @gray-light;
			.button-shadow(@swatch-7);
		}
	}
	.open {
		.dropdown-toggle {
			&:hover {
				background: @gray-light;
				.button-shadow(@swatch-7);
			}
		}
	}
}