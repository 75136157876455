.table {
	.button-shadow(@swatch-7);
	border-radius: @border-radius-base;
	overflow: hidden;

	thead > tr > th {
		background: @gray-dark;
		color: @white-color;
		font-family: @main-font-regular-bold;
		font-size: 14px;
		padding: 10px 0 7px 19px;
		text-transform: uppercase;
	}
	> tbody > tr > td {
		font-size: 15px;
		padding: 16px 5px 13px 20px;
	}
	.checkbox {
		margin: 0;
		label {
			padding: 0 0 0 37px;
		}
	}
}

// @media @max-xs {
// 	.table {
// 		> tbody > tr > td {
// 		}
// 	}
// }