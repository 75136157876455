.buy-prints-show {
	.wrapper {
		margin-bottom: 0;
	}
	.push {
		display: none;
	}
	.footer-wrapper {
		height: auto;
	}
	h1.bold {
		font-size: 46px;
		margin-top: 6px;
		color: #fff;
	}
	.checkout-cta {
		margin-top: 100px;
	}
	.footer-wrapper {
		padding-top: 0;
	}
	.head-img img {
		margin: 0 auto;
		width: 100%;
	}
}