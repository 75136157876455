div.subscribe-box {
	background: @generic-bg;
	margin-top: @grid-gutter-width;
	padding-top: 20px;
	padding-bottom: 20px;

	.btn {
		@media @max-xs {
			width: 100%;
		}
	}

	@media @min-sm {
		.info-box-wrapper {
		    margin-top: 0;
		    margin-bottom: 0;
		}
		.big-register-button {
			margin-top: 0;
		    padding-top: 15px;
			padding-bottom: 15px;
			font-size: 18px;
		}
	}
}