.search-head {
	background: @swatch-2;
	color: @swatch-8;
	font-size: 16px;
	padding: 12px 0;

  @media only print {
    display: none;
  }

	&-info {
		text-align: right;
		font-size: 14px;
		font-family: @main-font-regular-medium;
		color: @swatch-16;
		letter-spacing: .56px;
		padding-top: 0;

		.h5 {
			color: inherit;
			letter-spacing: inherit;
		}
	}
	&-sorting {
		display: flex;
		justify-content: flex-end;
		gap: 5px;

		.lightbox-files-badge {
			border-color: @swatch-7;
			color: @swatch-16;
			pointer-events: none;

			&:hover {
				background: transparent;
				border-color: @swatch-7;
				color: @swatch-16;
			}

			.badge {
				color: @white-color;
				background: @swatch-4;
			}
		}

		.open {
			.btn.dropdown-toggle {
				color: @swatch-16;
				border-color: @swatch-7;
				background: transparent;
			}
		}

		.btn.btn-default,
		.btn.btn-primary {
			color: @swatch-16;
			border-color: @swatch-7;

			&:hover,
			&:focus,
			&:active {
				color: @swatch-16;
				border-color: @swatch-7;
				background: transparent;
			}
		}

		.dropdown-menu {
			right: 0;
			left: auto;
		}
	}
	&-nav {
		padding-left: 0;
		list-style: none;
		margin: 0;

        @media @min-lg {
            text-align: start;
        }

		li {
			display: inline-block;
			padding-left: 14px;
			padding-right: 14px;
		}
		a {
			color: @swatch-16;
			font-family: @main-font-regular-bold;

			&:hover {
				color: @swatch-17;
				text-decoration: none;
			}
		}
	}
	.btn {
		margin-left: 14px;

		&:first-child {
			margin-left: 0;
		}
	}

	h1.bold {
		font-family: @main-font-decorate;
	}
}

@media @max-md {
	.search-head {
		&-nav {
			margin-bottom: 10px;
		}
		&-info {
			text-align: center;
		}
		&-sorting {
			text-align: center;
		}
	}
}

@media @max-xs {
	.search-head {
		&-info {
			text-align: center;
			width: 100%;
		}

		&-sorting {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.lightbox-files-badge {
				margin: 0 10px 10px;
			}
		}
	}
}
@media @min-sm {
	.search-head {

		h1.bold {
			font-size: 43px;
		}
	}
}
@media @sm-only {
	.search-head {
		.col-md-6 {
			text-align: center;
		}
	}
}
@media @min-lg {
	.search-head {
		&-nav {
			li {
				&:first-child {
					padding-left: 0;
				}
			}
		}
	}
}
